<!--
File: SectionEditForm.vue
Description: form for adding/editing a single road section.
-->
<template>
  <form v-if="isComponentActive">
    <md-card>
      <md-card-content>
        <VueTabs>
          <v-tab id="tab0" :title="$t('label.section_overview')">
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-40">
                <RegionsDropdown :label="$t('road_network.region')" v-model="section.fk_region" data-vv-name="fk_region"
                  :initial_value="section.fk_region" :is_required="true" @input='onRegionChange' />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-40">
                <DepsDropdown :label="$t('road_network.dep')" v-model="section.fk_deu" data-vv-name="fk_deu"
                  :initial_value="section.fk_deu" :is_required="true" :region_id="section.fk_region"
                  @input='onDeuChange' />
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-40">
                <RoadsDropdown :label="$t('road_network.road')" v-model="section.fk_road" data-vv-name="fk_road"
                  :initial_value="section.fk_road" :is_required="true" :region_id="section.fk_region"
                  @input='onRoadChange' />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field
                  :class="[{ 'md-valid': !errors.has('section_key') }, { 'md-error': errors.has('section_key') }]">
                  <label>{{ $t('road_network.section_key') }}</label>
                  <md-input v-model="section.section_key" type="text" data-vv-name="section_key" required
                    v-validate="modelValidations.section_key" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field
                  :class="[{ 'md-valid': !errors.has('section_number') }, { 'md-error': errors.has('section_number') }]">
                  <label>{{ $t('road_network.section_number') }}</label>
                  <md-input v-model="section.section_number" type="number" data-vv-name="section_number" required
                    v-validate="modelValidations.section_number" />
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-40">
                <md-field
                  :class="[{ 'md-valid': !errors.has('section_description') }, { 'md-error': errors.has('section_description') }]">
                  <label>{{ $t('stdCols.name_ru') }}</label>
                  <md-input v-model="section.section_description" type="text" data-vv-name="section_description" required
                    v-validate="modelValidations.section_description" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-40">
                <md-field :class="[{ 'md-valid': !errors.has('section_description_en') },
                { 'md-error': errors.has('section_description_en') }]">
                  <label>{{ $t('stdCols.name_en') }}</label>
                  <md-input v-model="section.section_description_en" type="text" data-vv-name="section_description_en"
                    required v-validate="modelValidations.section_description_en" />
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field :class="[{ 'md-valid': !errors.has('villages') }, { 'md-error': errors.has('villages') }]">
                  <label>{{ $t('inventory.villages') }}</label>
                  <md-input v-model="section.villages" type="number" data-vv-name="villages" required
                    v-validate="modelValidations.villages" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field :class="[{ 'md-valid': !errors.has('aadt') }, { 'md-error': errors.has('aadt') }]">
                  <label>{{ $t('inventory.aadt') }}</label>
                  <md-input v-model="section.aadt" type="number" data-vv-name="aadt" required
                    v-validate="modelValidations.aadt" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field :class="[{ 'md-valid': !errors.has('fclass') }, { 'md-error': errors.has('fclass') }]">
                  <label>{{ $t('inventory.fclass') }}</label>
                  <md-input v-model="section.fclass" type="number" data-vv-name="fclass" required
                    v-validate="modelValidations.fclass" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field
                  :class="[{ 'md-valid': !errors.has('black_spots') }, { 'md-error': errors.has('black_spots') }]">
                  <label>{{ $t('condition.black_spots') }}</label>
                  <md-input v-model="section.black_spots" type="number" data-vv-name="black_spots" required
                    v-validate="modelValidations.black_spots" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <label>
                  <input type="checkbox" v-model="section.social_importance" true-value="1" false-value="0" />
                  {{ $t('inventory.social_importance') }}
                </label>
              </div>
            </div>
          </v-tab>

          <v-tab id="tab1" :title="$t('label.section_location')">
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-40">
                <md-field>
                  <label>{{ $t('inventory.start_place') }}</label>
                  <md-input v-model="section.start_place" type="text" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-40">
                <md-field>
                  <label>{{ $t('inventory.end_place') }}</label>
                  <md-input v-model="section.end_place" type="text" />
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field
                  :class="[{ 'md-valid': !errors.has('start_distance_m') }, { 'md-error': errors.has('start_distance_m') }]">
                  <label>{{ $t('inventory.start_distance_m') }}</label>
                  <md-input v-model="section.start_distance_m" type="Number" data-vv-name="start_distance_m" required />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field
                  :class="[{ 'md-valid': !errors.has('end_distance_m') }, { 'md-error': errors.has('end_distance_m') }]">
                  <label>{{ $t('inventory.end_distance_m') }}</label>
                  <md-input v-model="section.end_distance_m" type="Number" required />
                </md-field>
              </div>
            </div>
          </v-tab>

          <v-tab id="tab2" :title="$t('label.section_geometry')">
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-40">
                <md-field>
                  <label>{{ $t('inventory.start_distance_m') }}</label>
                  <md-input v-model="sectionGeometry.start_distance_m" type="Number" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-40">
                <md-field>
                  <label>{{ $t('inventory.end_distance_m') }}</label>
                  <md-input v-model="sectionGeometry.end_distance_m" type="Number" />
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label>{{ $t('condition.right_shoulder_width_m') }}</label>
                  <md-input v-model="sectionGeometry.shoulder_width_m" type="Number" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <BaseDropdown :label="$t('condition.right_shoulder_type_desc')"
                  :initialValue="sectionGeometry.shoulder_type" :dropdownId='"shoulder_type"' :items="surface_types"
                  :displayField="'description'" :valueField="'description'" @onChange='onShoulderPavementTypeChange' />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <BaseDropdown :label="$t('tunnels_table.pavement_type_ru')" :initialValue="sectionGeometry.pavement_type"
                  :dropdownId='"pavement_types"' :items="pavement_types" :displayField="'description'"
                  :valueField="'description'" @onChange='onPavementTypeChange' />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <BaseDropdown :label="$t('condition.terrain_type_desc')" :initialValue="sectionGeometry.terrain_character"
                  :dropdownId='"terrain_character"' :items="terrain_types" :displayField="'description'"
                  :valueField="'description'" @onChange='onTerrainCharacterChange' />
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label>{{ $t('inventory.direction') }}</label>
                  <md-input v-model="sectionGeometry.direction" type="text" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label>{{ $t('label.dual') }}</label>
                  <md-input v-model="sectionGeometry.dual" type="Number" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <BaseDropdown :label="$t('road_network.nr_of_lanes')" :initialValue="sectionGeometry.number_of_lanes"
                  :dropdownId='"number_of_lanes"' :items="lanesList" :displayField="'description'"
                  :valueField="'description'" @onChange='onNumberOfLanesChange' />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label>{{ $t('label.carriageway_width') + $t('label.meters') }}</label>
                  <md-input v-model="sectionGeometry.carriageway_width_m" type="Number" />
                </md-field>
              </div>
            </div>
          </v-tab>
        </VueTabs>

        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import { VueTabs, VTab } from 'vue-nav-tabs'
  import 'vue-nav-tabs/themes/vue-tabs.css'

  import Swal from 'sweetalert2'
  //import MultilanguageEditForm from './MultilanguageEditForm.vue'
  import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'


  export default {
    name: 'section-edit-form',
    components: {
      VueTabs,
      VTab,
      RegionsDropdown,
      DepsDropdown,
      RoadsDropdown,
      BaseDropdown
    },
    props: {
      oper: String,
    },
    data() {
      return {
        sectionListLoaded: false,
        section_id: null,
        section: {},
        sectionGeometry: {
          fk_section: null,
          start_distance_m: null,
          end_distance_m: null,
          pavement_type: null,
          shoulder_width_m: null,
          shoulder_type: null,
          terrain_character: null,
          direction: null,
          dual: null,
          number_of_lanes: null,
          carriageway_width_m: null,
        }
        ,

        lanesList: [
          { description: 1 }, { description: 2 }, { description: 3 },
          { description: 4 }, { description: 5 }, { description: 6 }
        ],

        modelValidations: {
          section_key: { required: true, min: 3 },
          section_number: { required: true, numeric: true },
          section_description: { required: true, min: 3 },
          section_description_en: { required: true, min: 3 },
          //section_description_kg: { required: true, min: 3 },
          villages: { required: true, numeric: true, min_value: 0, max_value: 49 },
          aadt: { required: true, numeric: true },
          fclass: { required: false, numeric: true, min_value: 0, max_value: 3 },
          black_spots: { required: false, numeric: true, min_value: 0, max_value: 9 },
          social_importance: { required: false, numeric: true, min_value: 0, max_value: 1 },
          //start_place: { required: true, numeric: true },
          //end_place: { required: true, numeric: true },
          start_distance_m: { required: true, numeric: true },
          end_distance_m: { required: true, numeric: true },
          //start_geom: { required: false },
          //end_geom: { required: false },
        },
        isComponentActive: true,
      }
    },
    created() {
      const { sid = null } = this.$route.params
      this.section_id = sid
      if (this.oper === 'upd' && sid) {
        this.$store.dispatch('LOAD_SECTION_BY_ID', sid).then((res) => {
          this.sectionListLoaded = true
          this.section = Array.isArray(res) ? res[0] : res
        })
        this.$store.dispatch('LOAD_SECTION_GEOMETRY_ALL', sid).then((res) => {
          const len = this.$store.state.SectionGeometry.list.length
          if (len > 0) {
            this.sectionGeometry = this.$store.state.SectionGeometry.list[len - 1]
            delete this.sectionGeometry.geometry_id
            delete this.sectionGeometry.section
          } else {
            this.sectionGeometry.fk_section = sid
            this.sectionGeometry.start_distance_m = this.section.start_distance_m
            this.sectionGeometry.end_distance_m = this.section.end_distance_m
          }
        })
        this.$store.dispatch('LOAD_PAVEMENT_TYPES')
        this.$store.dispatch('LOAD_SURFACE_TYPES')
        this.$store.dispatch('LOAD_TERRAIN_TYPES')
      }
    },
    methods: {
      onClose() {
        this.$store.dispatch('SET_USE_HISTORY')
        this.$router.push('/inventory_data/sections')
      },
      onRegionChange() {
        //console.log("onRegionChange. fk_region: %d, fk_deu: %d", this.section.fk_region, this.section.fk_deu)
      },
      onDeuChange() {
        //console.log("onDeuChange. fk_deu: %d", this.section.fk_deu)
      },
      onRoadChange() {
      },
      onShoulderPavementTypeChange(value) {
        this.sectionGeometry.shoulder_type = value
      },
      onPavementTypeChange(value) {
        this.sectionGeometry.pavement_type = value
      },
      onTerrainCharacterChange(value) {
        this.sectionGeometry.terrain_character = value
      },
      onNumberOfLanesChange(value) {
        this.sectionGeometry.number_of_lanes = value
      },
      async validate() {
        this.$validator.validateAll().then((isValid) => {
          const alert = {
            type: 'success',
            title: this.$t('road_network.section'),
            text: this.$t('label.was_saved', {
              name_ru: this.section.section_description,
              name_en: this.section.section_description_en
            }),
            footer: ''
          }
          if (isValid) {
            //delete after Andrey resolves in Api
            // convert to numbers
            this.section.start_distance_m = Number(this.section.start_distance_m)
            this.section.end_distance_m = Number(this.section.end_distance_m)
            this.section.aadt = Number(this.section.aadt)
            this.section.section_number = Number(this.section.section_number)
            this.section.villages = Number(this.section.villages)
            this.section.social_importance = Number(this.section.social_importance)

            this.sectionGeometry.shoulder_width_m = Number(this.sectionGeometry.shoulder_width_m)
            this.sectionGeometry.carriageway_width_m = Number(this.sectionGeometry.carriageway_width_m)
            this.sectionGeometry.dual = Number(this.sectionGeometry.dual)
            this.sectionGeometry.shoulder_type = String(this.sectionGeometry.shoulder_type)
            this.sectionGeometry.terrain_character = String(this.sectionGeometry.terrain_character)
            this.sectionGeometry.number_of_lanes = String(this.sectionGeometry.number_of_lanes)
            this.sectionGeometry.direction = String(this.sectionGeometry.direction)

            const payload = this.oper === 'add' ? this.section : { id: this.section.section_id, section: this.section }
            this.$store.dispatch(`${this.oper.toUpperCase()}_SECTION`, payload).then(
              (res) => {
                // Add section id to the geometry object
                if (this.oper === 'add') this.sectionGeometry.fk_section = Number(res)

                this.$store.dispatch('ADD_SECTION_GEOMETRY', this.sectionGeometry)
                  .then((res2) => this.$nextTick(() => this.$validator.reset()))
              },
              (err) => {
                alert.type = 'error'
                alert.text = this.$t(`label.was_not_saved`, {
                  name_ru: this.section.section_description,
                  name_en: this.section.section_description_en
                })
                alert.footer = err
              }
            )
          } else {
            alert.footer = this.$t('messages.errors_count', { count: this.errors.items.length })
            return
          }
          Swal.fire(alert)
        })
        this.onClose()
      }
    },

    computed: {
      saveBtnDisabled() {
        return this.errors.items.length > 0
      },
      surface_types() {
        return this.$store.state.RefData.surface_types
      },
      pavement_types() {
        return this.$store.state.RefData.pavement_types
        //return [{description: "Paved"}, {description: "Unpaved"}]
      },
      terrain_types() {
        return this.$store.state.RefData.terrain_types
      },
    }
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}

.vue-tabs .nav-tabs>li.active>a,
.vue-tabs .nav-tabs>li.active>a:hover,
.vue-tabs .nav-tabs>li.active>a:focus {
  background-color: #4caf50;
}
</style>
